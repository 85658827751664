// 销售单
export const SALES_VIEW_ROUTE = {
    name: 'salesView',
    path: 'sales',
    meta: { name: '销售单', keepAlive: true, uid: 'salesView' },
    component: () =>
        import ('@/views/sales')
}

export const SALES_DETAIL_VIEW_ROUTE = {
    name: 'salesDetailView',
    path: 'sales/detail/:id',
    meta: { name: '销售单查看', keepAlive: true, uid: 'salesDetailView' },
    component: () =>
        import ('@/views/sales/detail')
}

// 发票单
export const SALES_INVOICE_VIEW_ROUTE = {
    name: 'salesInvoiceView',
    path: 'sales/invoice',
    meta: { name: '销售发票单', keepAlive: true, uid: 'salesInvoiceView' },
    component: () =>
        import ('@/views/sales/invoice')
}

export const SALES_INVOICE_DETAIL_VIEW_ROUTE = {
    name: 'salesInvoiceDetailView',
    path: 'sales/invoice/detail/:id',
    meta: { name: '销售发票单查看', keepAlive: true, uid: 'salesInvoiceDetailView' },
    component: () =>
        import ('@/views/sales/invoice/detail')
}

// 销售信息序时簿
export const FINANCE_DATA_SALES_VIEW_ROUTE = {
    name: 'financeDataSalesView',
    path: 'sales/financeData',
    meta: { name: '销售信息序时簿', keepAlive: true, uid: 'financeDataSalesView' },
    component: () =>
        import ('@/views/sales/financeData')
}